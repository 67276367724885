/* OrderBox.css */

.orderbox-container {
    overflow-y: scroll;
    height: 100%;
}

.orderbox-amount-buttons {
    height: 26px;
}

.orderbox-amount-button {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderbox-amount-percentage-button {
    font-size: 10px;
    padding: 3px 6px;
    position: relative;
}

.orderbox-amount-percentage-button:last-child:after {
    display: none;
}

.orderbox-amount-percentage-button:after {
    content: "";
    display: block;
    height: 8px;
    width: 1px;
    background: #25272D;
    position: absolute;
    right: 0;
    top: 6px;
}

.orderbox-amount-percentage-button:hover {
    color: #fff;
}



/*Lite*/

.orderbox-lite-wrapper {
    width: 30%;
    min-width: 360px;
}

@media (max-width: 1050px) {
    .orderbox-lite-wrapper {
        width: 100%;
    }
}

/* Positions.css */

.positions-container-wrapper {
  flex-wrap: wrap;
  flex: 1;
}

.positions-container-wrapper-small-full {
    height: calc(100% - 34px);
}

.positions-container-positions {
  width: calc(100vw - 480px);
}

.positions-container-wrapper {
}
.positions-container-header {
}

.positions-block-wrapper {
    height: 100%;
}

.positions-container-wrapper-small.isLite .positions-block-wrapper {
    height: calc(100% - 54px);
}

.positions-container-wrapper-small-1\/3 {
    overflow: hidden;
    height: calc(100% / 3);
    flex: calc(100% / 3) 0;
}

.positions-container-wrapper-small-24p {
    overflow: hidden;
    height: 24%;
    flex: 24%;
}
.positions-container-wrapper-small-38p {
    overflow: hidden;
    height: 38%;
    flex: 38%;
}

@media (max-height: 1000px) and (min-width: 1050px) {
  .positions-container-item-wrapper_large .order-table-wrapper {
    height: 334px;
    max-height: 334px;
  }
}

@media (max-height: 1000px) and (min-width: 1050px) {
  .positions-container-wrapper-small {
      height: calc(100vh - 634px);
      min-height: 200px;
  }
  .positions-block-wrapper {
      height: calc(100% - 34px);
      display: flex;
      flex-direction: column;
  }
    .order-table-wrapper {
        max-height: none;
    }

  .order-table-wrapper {
      flex: 1;
      height: calc(100% - 34px);
  }
}

@media (min-height: 1100px) {
    .positions-container-wrapper-small {
        height: calc(100vh - 634px);
        min-height: 200px;
    }
}

@media (max-width: 1380px) {
    .positions-container-wrapper-small {
        height: calc(100vh - 670px);
        min-height: 200px;
    }
}

@media (min-height: 1000px) and (min-width: 1280px) {
  .positions-container-wrapper {
    flex: 1;
    height: calc(100vh - 700px);
  }
  .positions-container-wrapper-small {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 700px);
  }

  .positions-container-positions,
  .positions-container-trades {
    height: 100%;
  }

  .positions-h-1400-left-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@media (min-height: 1000px) {
    .order-table-wrapper {
        height: calc(100% - 34px);
        max-height: calc(100% - 34px);
    }
}


@media (max-width: 800px) {
    .positions-container-wrapper-small {
        height: auto !important;
        max-height: none;
    }
}

@media (max-width: 1050px) {
  .positions-container-wrapper {
    flex-direction: column;
  }

  .positions-container-positions {
    width: 100%;
  }

  .positions-container-trades {
    width: 100%;
    border-left: none;
  }

  .positions-container-wrapper-small-24p,
  .positions-container-wrapper-small-38p {
      height: 200px;
      flex: 200px 0;
  }
}

@media (min-width: 2400px) {
  .positions-container-positions {
    width: 62%;
  }

  .positions-container-trades {
    width: 38%;
  }
}


@media (min-width: 2400px) and (min-height: 1400px) {
    .table-headline {
        display: block;
    }
}

@media (min-height: 1000px) {
  .positions-container-item-wrapper {
    display: block !important;
  }
  .positions-container-header {
    display: none;
  }
  .order-closed-left {
    display: none !important;
  }
  .order-closed-right {
    display: block !important;
  }

  .positions-container-wrapper-small-1\/2 {
    overflow: hidden;
    height: 50%;
    flex: calc(100% / 2) 0;
  }

  .positions-container-wrapper-small-1\/2.first {
    overflow: hidden;
    height: 38%;
    flex: 38% 0;
  }
  .positions-container-wrapper-small-1\/2.second {
    overflow: hidden;
    height: 62%;
    flex: 62% 0;
  }
}

/* Wallets.css */

.wallets-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: block;
    position: absolute;
    right: -16px;
    background-color: #000;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 0;
    z-index: 100;
}

.dropdown-content ul {
    list-style-type: none;
    padding: 0 16px;
}

.dropdown-content li {
    margin-bottom: 10px;
}

@media (max-width: 640px) {
    .dropdown-content {
        width: 100vw !important;
        right: -8px !important;
        margin: 0 !important;
    }
}


/*.wallets-dropdown-list {*/
/*    position: relative;*/
/*    max-height: 200px;*/
/*    overflow-y: auto;*/
/*}*/

/*.wallets-dropdown-list::-webkit-scrollbar {*/
/*     width: 2px;*/
/*     height: 0;*/
/* }*/

/*.wallets-dropdown-list::-webkit-scrollbar-track {*/
/*     background: #00b8d4;*/
/*     border-radius: 2px;*/
/* }*/

/*.wallets-dropdown-list::-webkit-scrollbar-thumb {*/
/*     background: #0c3299;*/
/*     border-radius: 2px;*/
/* }*/


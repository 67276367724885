/* Header.css */
.news-carousel {
  display: flex;
  /* Dynamically adjust min-width via inline style in React component instead */
  animation: marquee 2000s linear infinite; /* Adjust the duration as needed */
}

.news-item {
  flex: 0 0 auto; /* Allow each news item to take its natural width */
  margin-right: 16px; /* Adjust the margin as needed */
}

@keyframes marquee {
  0% {
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -ms-transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}


/* Header.css */
.ticker-carousel {
    display: flex;
    /* Dynamically adjust min-width via inline style in React component instead */
    animation: marquee-ticker 120s linear infinite; /* Adjust the duration as needed */
}
@keyframes marquee-ticker {
    0% {
        -ms-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -ms-transform: translate3d(-100%, 0, 0);
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

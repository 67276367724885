/* OrderBook.css */

.order-book-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*border: 1px solid #000;*/
    /*height: 100%;*/
}

.order-book-section {
    width: 100%; /* Adjust the width as needed */
    margin-bottom: 1rem;
}

.order-book-section h2 {
    text-align: center;
}

.order-item {
    /*display: grid;
    grid-template-columns: 1fr 100px 1fr; !* Adjust the middle column width as needed *!
    gap: 0.5rem; !* Spacing between columns *!
    align-items: center;*/
}

.price, .amount, .cumulative {
    display: block;
    text-align: right;
    padding-right: 1rem; /* Adjust padding for alignment */
}

/* Ensure that the middle column (.amount) has a fixed width */
.amount {
    width: 100px; /* Fixed width for the amount column */
}

.owner-indicator {
    text-align: center;
}

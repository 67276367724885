
.order-table-wrapper {
    width: 100%;
    height: 150px;
    max-height: 150px;
    overflow-y: scroll;
    border-bottom: 1px solid #25272d;
    -webkit-overflow-scrolling:touch;

}

.order-table-wrapper thead {
    position: relative;
    z-index: 1;
}
.order-table-wrapper tbody tr td {
    z-index: 0;
    position: relative;
}

.order-table {
    min-width: 800px;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
}

.order-table thead {
    position: sticky;
    top: 0;
}

.order-table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}

.order-table thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 #25272d, inset 0 -1px 0 #25272d;
}

.order-table thead th {
    background-clip: padding-box
}

.order-table th {
    background: #101214;
}

.order-table td,
.order-table th {
    border: 1px solid #25272d;
}

.order-table td:first-child,
.order-table th:first-child {
    border-left: none;
}

.order-table td:last-child,
.order-table th:last-child {
    border-right: none;
}

.order-table td {
    padding: 6px 6px;
}

.order-table th {
    padding: 4px 6px;
    /*background: #101214;*/
    /*background: rgba(0, 0, 0, 0.3);*/
    color: #929397;
    font-size: 10px;
    font-weight: 500;
}

.order-table-wrapper.isLite {
    border: none;
}

.order-table.isLite th {
    background: #000;
    box-shadow: none;
    padding: 4px 6px 12px;
}

.order-table.isLite th, .order-table.isLite td {
    border: none;
}

.order-table.isLite tbody td {
    padding: 0;
}

/*.order-table.isLite tbody td > div {
    background: #0B0C0D;
    border-top: 1px solid #181A1E;
    border-bottom: 1px solid #181A1E;
    padding: 12px 6px;
    margin: 4px 0;
}

.order-table.isLite tbody td:first-child > div {
    border-left: 1px solid #181A1E;
}
.order-table.isLite tbody td:last-child > div {
    border-right: 1px solid #181A1E;
}*/

.order-table.isLite tbody td {
    position: relative;
    padding: 15px 6px 23px;
}

.order-table.isLite tbody td:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - 8px);
    background: #0B0C0D;
    border-top: 1px solid #181A1E;
    border-bottom: 1px solid #181A1E;
}

.order-table.isLite tbody td:first-child:before {
    border-left: 1px solid #181A1E;
}
.order-table.isLite tbody td:last-child:before {
    border-right: 1px solid #181A1E;
}


.container-scroll {
    position: relative;
    overflow-y: auto;
}

.container-scroll::-webkit-scrollbar {
    position: absolute;
    width: 2px;
    height: 2px;
}

.container-scroll::-webkit-scrollbar-track {
    background: #141924;
    border-radius: 2px;
}

.container-scroll::-webkit-scrollbar-thumb {
    background: #8D9098;
    border-radius: 2px;
}

/* Assets.css */

.assets-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

@keyframes flashGreen {
    0% { background-color: rgba(105, 170, 77, 0.15); }
    50% { background-color: rgba(105, 170, 77, 0.30); }
    100% { background-color: transparent; }
}

@keyframes flashRed {
    0% { background-color: rgba(216, 68, 57, 0.15); }
    50% { background-color: rgba(216, 68, 57, 0.30); }
    100% { background-color: transparent; }
}

.flash-green {
    animation: flashGreen 150ms ease-out forwards;
}

.flash-red {
    animation: flashRed 150ms ease-out forwards;
}


/* Chart.css */

.TVChartContainer {
    height: calc(100% - 66px);
}

.TVChartContainer > iframe {
    height: 100% !important;
}

.html.theme-dark .chart-page .chart-container-border,
.chart-markup-table.pane {
    background: #000 !important;
}

/*:root:not(.theme-dark) {
    --tv-color-platform-background: #d1c4e9;
    --tv-color-pane-background: rgb(251, 223, 244);
    --tv-color-toolbar-button-background-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-expanded: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-active: rgb(249, 185, 233);
    --tv-color-toolbar-button-background-active-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-text: rgb(136, 24, 79);
    --tv-color-toolbar-button-text-hover: rgb(74, 20, 140);
    --tv-color-toolbar-button-text-active: red;
    --tv-color-toolbar-button-text-active-hover: red;
    --tv-color-item-active-text: rgb(6, 6, 255);
    --tv-color-toolbar-toggle-button-background-active: red;
    --tv-color-toolbar-toggle-button-background-active-hover: magenta;
    --tv-color-toolbar-divider-background: rgb(251, 223, 244);
    --tv-color-toolbar-save-layout-loader: rgb(106, 109, 120);

    --tv-color-popup-background: rgb(241, 188, 225);
    --tv-color-popup-element-text: rgb(136, 24, 79);
    --tv-color-popup-element-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-background-hover: rgb(244, 143, 177);
    --tv-color-popup-element-divider-background: rgb(251, 223, 244);
    --tv-color-popup-element-secondary-text: rgb(74, 20, 140);
    --tv-color-popup-element-hint-text: rgb(74, 20, 140);
    --tv-color-popup-element-text-active: rgb(6, 6, 255);
    --tv-color-popup-element-background-active: red;
    --tv-color-popup-element-toolbox-text: rgb(136, 24, 79);
    --tv-color-popup-element-toolbox-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-text-active-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-background-hover: rgb(222, 89, 132);
    --tv-color-popup-element-toolbox-background-active-hover: magenta;
}

.theme-dark:root {
    --tv-color-platform-background: #d1c4e9;
    --tv-color-pane-background: rgb(251, 223, 244);
    --tv-color-toolbar-button-background-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-expanded: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-active: rgb(249, 185, 233);
    --tv-color-toolbar-button-background-active-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-text: rgb(136, 24, 79);
    --tv-color-toolbar-button-text-hover: rgb(74, 20, 140);
    --tv-color-toolbar-button-text-active: red;
    --tv-color-toolbar-button-text-active-hover: red;
    --tv-color-item-active-text: rgb(6, 255, 6);
    --tv-color-toolbar-toggle-button-background-active: red;
    --tv-color-toolbar-toggle-button-background-active-hover: magenta;
    --tv-color-toolbar-divider-background: rgb(251, 223, 244);
    --tv-color-toolbar-save-layout-loader: rgb(134, 137, 147);

    --tv-color-popup-background: rgb(241, 188, 225);
    --tv-color-popup-element-text: rgb(136, 24, 79);
    --tv-color-popup-element-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-background-hover: rgb(244, 143, 177);
    --tv-color-popup-element-divider-background: rgb(251, 223, 244);
    --tv-color-popup-element-secondary-text: rgb(74, 20, 140);
    --tv-color-popup-element-hint-text: rgb(74, 20, 140);
    --tv-color-popup-element-text-active: rgb(6, 6, 255);
    --tv-color-popup-element-background-active: red;
    --tv-color-popup-element-toolbox-text: rgb(136, 24, 79);
    --tv-color-popup-element-toolbox-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-text-active-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-background-hover: rgb(222, 89, 132);
    --tv-color-popup-element-toolbox-background-active-hover: magenta;
}
*/
